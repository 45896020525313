<template>
  <wujie-vue
    width="100%"
    height="100%"
    name="admin-vue3"
    :props="wujieprops"
    :url="vue3Url"
    :sync="true"
  ></wujie-vue>
</template>

<script>
import wujieVue from "wujie-vue2";

import { ADMIN_VUE3_ADDRESS } from "@/config";
import router from "@/router";
import Utils from "@/utils";
export default {
  data() {
    return {
      vue3Url: ADMIN_VUE3_ADDRESS + this.$route.params.path,
      wujieprops: {
        jump: (name) => {
          router.push({ name });
        },
        token: Utils.getToken(),
        initUrl: "",
      },
    };
  },
  watch: {
    "$route.params.path": {
      handler: function () {
        wujieVue.bus.$emit("router-change", `/${this.$route.params.path}`);
      },
      immediate: true,
    },
  },
  created() {
    this.wujieprops.initUrl = "/" + this.$route.params.path;
  },
};
</script>

<style scoped lang="scss"></style>
